<template>
  <div
    class="rounded-md border-[1.5px] border-surface-200 h-8 w-8 flex justify-center items-center overflow-hidden bg-white shrink-0 shadow-sm"
  >
    <span
      v-if="!workspace.profileImageUrl"
      class="font-semibold text-primary-700 text-2xs tracking-[-0.01em]"
      >{{ workspaceTitleAbbreviation }}</span
    >

    <img
      v-if="workspace.profileImageUrl"
      :src="workspace.profileImageUrl"
      class="h-full w-full object-cover"
    />
  </div>
</template>

<script setup lang="ts">
interface WorkspaceTitleAndId {
  title: string
  profileImageUrl?: string
  id: string
}

type ExtendsWorkspaceTitleAndId = Record<any, any> & WorkspaceTitleAndId

const props = defineProps({
  workspace: {
    type: Object as PropType<ExtendsWorkspaceTitleAndId>,
    required: true,
  },
})
const workspaceTitleAbbreviation = computed(() => {
  const splitTitle = props.workspace.title.split(/[^a-zA-Z0-9]+/)
  if (splitTitle.length === 1) {
    return `${splitTitle[0].toUpperCase().charAt(0)}${splitTitle[0]
      .toUpperCase()
      .charAt(1)}`
  } else {
    return `${splitTitle[0].toUpperCase().charAt(0)}${splitTitle[1]
      .toUpperCase()
      .charAt(0)}`
  }
})
</script>
