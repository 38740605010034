<!-- Wrap activator (button) with AppMenu. Use slot prop `toggleMenu` to toggle menu. Pass menu items to prop items.
E.g. `<AppMenu v-slot="{ toggleMenu }" :items="items"> <Button @click="toggleMenu" /> </AppMenu>` -->
<template>
  <TieredMenu
    ref="menu"
    :model="items"
    :popup="true"
  >
    <template #item="{ item }">
      <a
        class="flex w-full justify-between items-center py-2 px-3 select-none cursor-pointer gap-2 overflow-hidden"
      >
        <div class="flex justify-start items-center gap-2 grow overflow-hidden">
          <font-awesome-icon
            v-if="item.icon"
            :icon="item.icon"
            class="text-surface-800"
            fixed-width
          />
          <span class="text-sm text-surface-800 truncate">{{
            item.label
          }}</span>
        </div>

        <AppIcon
          v-if="item.items && item.items.length > 0"
          icon="fa-solid fa-chevron-right"
          class="text-surface-600 text-2xs shrink-0"
        />
      </a>
    </template>
  </TieredMenu>
  <slot :toggle-menu="toggleMenu" />
</template>

<script setup lang="ts">
import type { MenuItem } from 'primevue/menuitem'
import TieredMenu from 'primevue/tieredmenu'

defineProps({
  items: {
    type: Array as PropType<MenuItem[]>,
    required: true,
  },
})

const menu = ref()

function toggleMenu(event: MouseEvent) {
  menu.value.toggle(event)
}
</script>
