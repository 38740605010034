<template>
  <AppMenuTiered
    v-slot="{ toggleMenu }"
    :items="menuItems"
  >
    <button
      class="h-10 text-surface-800 bg-transparent hover:bg-surface-100 duration-150 rounded-md overflow-hidden"
      @click="toggleMenu"
    >
      <div
        v-if="
          workspaceStore.currentWorkspace &&
          userStore.user &&
          !workspaceStore.$isInitialising
        "
        class="h-full grid grid-cols-[2.5rem_auto_0.75rem] items-center gap-2 overflow-hidden pr-3"
      >
        <div class="flex justify-center items-center">
          <AppWorkspaceProfileImageSmall
            :workspace="workspaceStore.currentWorkspace"
          />
        </div>
        <span
          class="text-left text-sm font-medium truncate grow tracking-tight"
          >{{ workspaceStore.currentWorkspace.title }}</span
        >
        <AppIcon
          icon="fa-solid fa-chevron-down"
          class="text-2xs text-surface-600 shrink-0"
        />
      </div>

      <div
        v-else
        class="h-full w-full animate-pulse bg-surface-300"
      ></div>
    </button>
  </AppMenuTiered>
</template>

<script setup lang="ts">
import { UserRole } from '~/types/User'
import { useAuthStore } from '~/stores/auth'
import { useUserStore } from '~/stores/user'
import { useWorkspaceStore } from '~/stores/workspace'

const authStore = useAuthStore()
const userStore = useUserStore()
const workspaceStore = useWorkspaceStore()
const router = useRouter()

const menuItems = computed(() => {
  const workspaceOptions = []

  if (
    !userStore.$isInitialising &&
    userStore.user &&
    !workspaceStore.$isInitialising
  ) {
    workspaceOptions.push(
      ...userStore.user.workspaces
        .filter((ws) => ws.id !== workspaceStore.currentWorkspaceId)
        .map((ws) => ({
          label: ws.title,
          command: async () =>
            await router.push({
              name: 'workspaceId',
              params: { workspaceId: ws.id },
            }),
        })),
    )
  }

  const systemAdminOption = []

  if (userStore.userRole === UserRole.Super) {
    systemAdminOption.push({
      label: 'System Admin',
      icon: 'fa-regular fa-gear-complex-code',
      command: async () => {
        if (userStore.userRole !== UserRole.Super) {
          return
        }

        await router.push({ name: 'system-admin-users' })
      },
    })
  }

  return [
    {
      label: 'Workspace settings',
      icon: 'fa-regular fa-gear',
      command: async () => await router.push({ name: 'workspaceId-workspace' }),
    },
    {
      separator: true,
    },
    {
      label: 'Switch workspace',
      icon: 'fa-regular fa-arrows-repeat',
      items: workspaceOptions,
    },
    ...systemAdminOption,
    {
      icon: 'fa-regular fa-arrow-right-from-bracket',
      label: 'Sign out',
      command: async () => await authStore.signOut(),
    },
  ]
})
</script>

<style scoped></style>
