<template>
  <NavBar>
    <!-- WORKSPACE MENU -->
    <template #top>
      <NavBarWorkspaceButton />
    </template>

    <template
      v-if="workspaceId"
      #nav-items
    >
      <NavBarGroup>
        <NavBarItem
          label="Summary"
          icon="fa-solid fa-house"
          to-name="workspaceId"
        />
        <NavBarItem
          v-if="getFeatureActive('SharedAssets')"
          label="Shared Assets"
          icon="fa-solid fa-book-sparkles"
        />
        <NavBarItem
          label="Tags"
          icon="fa-solid fa-tags"
          to-name="workspaceId-tags"
        />
      </NavBarGroup>

      <NavBarGroup label="Simulations">
        <NavBarItem
          label="Environments"
          icon="fa-solid fa-circles-overlap"
          to-name="workspaceId-environments"
        />
        <NavBarItem
          label="Simulations"
          icon="fa-solid fa-island-tropical"
          to-name="workspaceId-simulations"
        />
        <NavBarItem
          label="Characters"
          icon="fa-solid fa-person-running"
          to-name="workspaceId-characters"
        />
      </NavBarGroup>
      <NavBarGroup
        v-if="getFeatureActive('Assessments')"
        label="Assessments"
      >
        <NavBarItem
          label="Assessments"
          icon="fa-solid fa-flask-vial"
          to-name="workspaceId-assessments"
        />
        <NavBarItem
          label="Tests"
          icon="fa-solid fa-vial"
          to-name="workspaceId-tests"
        />
        <NavBarItem
          v-if="getFeatureActive('RubricTemplates')"
          label="Rubric Templates"
          icon="fa-solid fa-table"
        />
      </NavBarGroup>
    </template>
  </NavBar>
</template>

<script setup lang="ts">
import { useRouteParams } from '@vueuse/router'
import { getFeatureActive } from '~/utils/getFeatureActive'

// race condition fix:
// nav bar takes too long to swtich between workspace nav and system admin nav before NuxtLinks error out because of missing workspaceId
const workspaceId = useRouteParams('workspaceId')
</script>
